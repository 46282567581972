import {
    Document,
    Page,
    Text,
    View,
    Font,
    StyleSheet,
    PDFViewer,
    pdf
  } from "@react-pdf/renderer";
  import genotypes_dictionary from "./convertGenotype";
  import info from "./GenotypeInfo"
  import { saveAs } from "file-saver";
  
  // Register Font
  Font.register({
    family: 'Inter',
    fonts: [
      {
        src: 'https://fonts.gstatic.com/s/inter/v12/UcCO3FwrK3iLTeHuS_fvQtMwCp50KnMw2boKoduKmMEVuLyeMZhrib2Bg-4.ttf',
        fontWeight: 100,
      },
      {
        src: 'https://fonts.gstatic.com/s/inter/v12/UcCO3FwrK3iLTeHuS_fvQtMwCp50KnMw2boKoduKmMEVuDyfMZhrib2Bg-4.ttf',
        fontWeight: 200,
      },
      {
        src: 'https://fonts.gstatic.com/s/inter/v12/UcCO3FwrK3iLTeHuS_fvQtMwCp50KnMw2boKoduKmMEVuOKfMZhrib2Bg-4.ttf',
        fontWeight: 300,
      },
      {
        src: 'https://fonts.gstatic.com/s/inter/v12/UcCO3FwrK3iLTeHuS_fvQtMwCp50KnMw2boKoduKmMEVuLyfMZhrib2Bg-4.ttf',
        fontWeight: 400,
      },
      {
        src: 'https://fonts.gstatic.com/s/inter/v12/UcCO3FwrK3iLTeHuS_fvQtMwCp50KnMw2boKoduKmMEVuI6fMZhrib2Bg-4.ttf',
        fontWeight: 500,
      },
      {
        src: 'https://fonts.gstatic.com/s/inter/v12/UcCO3FwrK3iLTeHuS_fvQtMwCp50KnMw2boKoduKmMEVuGKYMZhrib2Bg-4.ttf',
        fontWeight: 600,
      },
      {
        src: 'https://fonts.gstatic.com/s/inter/v12/UcCO3FwrK3iLTeHuS_fvQtMwCp50KnMw2boKoduKmMEVuFuYMZhrib2Bg-4.ttf',
        fontWeight: 700,
      },
      {
        src: 'https://fonts.gstatic.com/s/inter/v12/UcCO3FwrK3iLTeHuS_fvQtMwCp50KnMw2boKoduKmMEVuDyYMZhrib2Bg-4.ttf',
        fontWeight: 800,
      },
      {
        src: 'https://fonts.gstatic.com/s/inter/v12/UcCO3FwrK3iLTeHuS_fvQtMwCp50KnMw2boKoduKmMEVuBWYMZhrib2Bg-4.ttf',
        fontWeight: 900,
      },
    ],
  });
  
  const tableData = [
    { gene: 'actn3', geneName: "ACTN3", snpNo: "rs1815739", pubmedRef: "26231489" },
    { gene: 'ppara', geneName: "PPARa", snpNo: "rs4253778", pubmedRef: "26231489" },
    { gene: 'adrb2', geneName: "ADRB2", snpNo: "rs1042713", pubmedRef: "26231489" },
    { gene: 'ppargc1a', geneName: "PPARGC1a", snpNo: "rs8192678", pubmedRef: "26231489" },
    { gene: 'nos3', geneName: "NOS3", snpNo: "rs2070744", pubmedRef: "26231489" },
    { gene: 'hif1a', geneName: "HIF1a", snpNo: "rs11549465", pubmedRef: "26231489" },
    { gene: 'il6', geneName: "IL-6", snpNo: "rs1800795", pubmedRef: "26231489" },
    { gene: 'comt', geneName: "COMT", snpNo: "rs4680", pubmedRef: "34681035" },
    { gene: 'bdnf', geneName: "BDNF", snpNo: "rs6265", pubmedRef: "33306590" },
    { gene: 'mct1', geneName: "MCT-1", snpNo: "rs1049434", pubmedRef: "26231489" },
    { gene: 'col1a1', geneName: "COL1A1", snpNo: "rs1800012", pubmedRef: "35337603" },
    { gene: 'col5a1', geneName: "COL5A1", snpNo: "rs12722", pubmedRef: "35337603" },
    { gene: 'cyp1a2', geneName: "CYP1A2", snpNo: "rs762551", pubmedRef: "29509641" },
  ];
  

  
  // Create styles
  const styles = StyleSheet.create({
    page: {
      backgroundColor: "white",
      color: "black",
      fontFamily: "Inter"
    },
    section: {
      margin: 20,
      padding: 10,
    },
  
    genesection: {
      marginRight: 20,
      marginLeft: 20,
      marginTop: 10,
      padding: 10,
    },
  
    title: {
      display: "flex",
      alignItems: "center",
      fontSize: "16px",
      padding: 20,
      fontWeight: 800,
    },
    infotitle: {
      display: "flex",
      alignItems: "left",
      fontSize: "12px",
      padding: 2,
    },
  
    tableheadercontainer: {
      display: "flex",
      flexDirection: "row",
  
    },
  
    table: {
      display: "table",
      width: "auto",
  
  
    },
  
    tableRow: {
      margin: "auto",
      flexDirection: "row"
    },
    tableCol: {
      width: "23%",
      borderStyle: "solid",
      borderWidth: 1,
  
    },
  
    tableHeaderCell: {
      margin: "auto",
      padding: "5",
      fontSize: 12,
      fontWeight: 700,
  
    },
  
    tableCell: {
      margin: 5,
      fontSize: 10,
      alignSelf: "center"
    },
  
    viewer: {
        marginTop: "20px",
      width: "100%",
      height:"90vh"
    },
  });
  
  // Create Document Component
  const MyDocument = ({ selectedRowData, swapcode, name, birthDate }) => {
    const analysisDate = new Date(); 
const formattedAnalysisDate = `${analysisDate.getDate()}/${analysisDate.getMonth() + 1}/${analysisDate.getFullYear()}`;

    return (
      
      // <PDFViewer style={styles.viewer}>
        <Document title={swapcode}>
          <Page size="A4" style={styles.page}>
            <View style={styles.title}>
              <Text>AKGEN ANALİZ SONUCU</Text>
            </View>
            <View style={styles.section}>
              <Text style={styles.infotitle}><Text style={{fontWeight: 600}}>Ad ve Soyad:</Text> {name}</Text>
              <Text style={styles.infotitle}><Text style={{fontWeight: 600}}>Doğum Tarihi:</Text> {birthDate}</Text>
              <Text style={styles.infotitle}>
                <Text style={{fontWeight: 600}}>Numune No:</Text> {swapcode}</Text>
              <Text style={styles.infotitle}><Text style={{fontWeight: 600}}>Numune Tipi:</Text> Yanak içi epitel doku</Text>
              <Text style={styles.infotitle}><Text style={{fontWeight: 600}}>Analiz Tarihi:</Text> {formattedAnalysisDate} </Text>
            </View>
  
            <View style={styles.table}>
              <View style={styles.tableRow}>
                <View style={styles.tableCol}>
                  <Text style={styles.tableHeaderCell}>GEN ADI</Text>
                </View>
                <View style={styles.tableCol}>
                  <Text style={styles.tableHeaderCell}>SNP NO</Text>
                </View>
                <View style={styles.tableCol}>
                  <Text style={styles.tableHeaderCell}>PUBMED REFERANSI</Text>
                </View>
                <View style={styles.tableCol}>
                  <Text style={styles.tableHeaderCell}>GENOTİP</Text>
                </View>
              </View>
  
              {/* Table Data */}
              {tableData.map((tablerow) => (
  
                <View style={styles.tableRow}>
                  <View style={styles.tableCol}>
                    <Text style={styles.tableCell}>{tablerow.geneName}</Text>
                  </View>
                  <View style={styles.tableCol}>
                    <Text style={styles.tableCell}>{tablerow.snpNo}</Text>
                  </View>
                  <View style={styles.tableCol}>
                    <Text style={styles.tableCell}>{tablerow.pubmedRef}</Text>
                  </View>
                  {/* Add data from GenotypeListPage */}
                  {tablerow.gene in (selectedRowData ?? {}) && selectedRowData[tablerow.gene] !== undefined ? (
                    <View style={styles.tableCol}>
                      <Text style={styles.tableCell}>{genotypes_dictionary[tablerow.gene][selectedRowData[tablerow.gene]]}</Text>
                    </View>
                  ) : (
                    <View style={styles.tableCol}>
                      <Text style={styles.tableCell}>Test Yapılmadı</Text>
                    </View>
                  )}
                </View>
              ))}
            </View>
          </Page>
  
          <Page size="A4" style={styles.page}>
            {["actn3", "ppara", "ppargc1a", "bdnf", "col1a1"].map((key) => (
              <View style={styles.genesection}>
                <Text style={styles.infotitle}><Text style={{fontWeight: "700"}}>{key.toUpperCase()} </Text></Text>
                <Text style={styles.infotitle}>{info[key]["info"]}</Text>
                <Text style={styles.infotitle}><Text style={{fontWeight: "700"}}>{genotypes_dictionary[key][selectedRowData[key]]} </Text>Genotipi:</Text>
                <Text style={styles.infotitle}>{info[key]["genotype"][genotypes_dictionary[key][selectedRowData[key]]]}</Text>
              </View>
            ))}
          </Page>
          <Page size="A4" style={styles.page}>
            {["il6", "adrb2", "nos3", "hif1a"].map((key) => (
              <View style={styles.genesection}>
                <Text style={styles.infotitle}><Text style={{fontWeight: "700"}}>{key.toUpperCase()}</Text></Text>
                <Text style={styles.infotitle}>{info[key]["info"]}</Text>
                <Text style={styles.infotitle}><Text style={{fontWeight: "700"}}>{genotypes_dictionary[key][selectedRowData[key]]} </Text> Genotipi:</Text>
                <Text style={styles.infotitle}>{info[key]["genotype"][genotypes_dictionary[key][selectedRowData[key]]]}</Text>
              </View>
            ))}
          </Page>
  
          <Page size="A4" style={styles.page}>
            {["col5a1", "mct1", "comt", "cyp1a2",].map((key) => {
  
                if (selectedRowData.hasOwnProperty(key) && selectedRowData[key] !== undefined) {
                return (
                  <View style={styles.genesection}>
                    <Text style={styles.infotitle}><Text style={{fontWeight: "700"}}>{key.toUpperCase()}</Text></Text>
                    <Text style={styles.infotitle}>{info[key]["info"]}</Text>
                    <Text style={styles.infotitle}><Text style={{fontWeight: "700"}}>{genotypes_dictionary[key][selectedRowData[key]]}</Text> Genotipi:</Text>
                    <Text style={styles.infotitle}>{info[key]["genotype"][genotypes_dictionary[key][selectedRowData[key]]]}</Text>
                  </View>);
              } else {
                return (
                  <View style={styles.genesection}>
  
                  </View>
                )
              }
            })}
          </Page>
        </Document>
      // </PDFViewer>
    );
  }
  export default function GenotypePDFReport({ selectedRowData, swapcode, name, birthDate }) {
    
    const saveFile = () => {
      // This does the trick!
      pdf(<MyDocument   selectedRowData={selectedRowData} swapcode={swapcode} name={name} birthDate={birthDate} />)
        .toBlob()
        .then((blob) => saveAs(blob, `${swapcode}.pdf`));
    };
  
    return (
      <div>
       <div>
          <button style={{ 
    padding: "1%",
    backgroundColor: "#415A78",
    color: "white",
    borderRadius: "10px",
    border: "none"}} onClick={saveFile}>PDF'i kaydet</button>
        </div>
        <PDFViewer style={styles.viewer}>
        <MyDocument selectedRowData={selectedRowData} swapcode={swapcode} name={name} birthDate={birthDate} />
        </PDFViewer>
  
       
      </div>
    );
  }
