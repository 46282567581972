import { useState,useEffect } from "react";
import axios from "axios";

export default function Admin() {
  const [admin, setAdmin] = useState(null);
  
  useEffect(() => {
    async function findAdmin() {
      
      
        await axios
        .get("/api2/token")
        .then((res) => {

            console.log(res)
          
            setAdmin(res.data.currentuser);
          
          
          
        })
        .catch((err) => {
          console.log(err);
        });
    }

    findAdmin();
  }, []);

  return {
    admin,
    setAdmin,
  };
}
