import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import RegisterPlate from "./pages/RegisterPlate"
import GenotypeList from "./pages/GenotypeList"
import RegisterGenotype from "./pages/RegisterGenotype"
import Login from "./pages/Login";
import  {AdminContext}  from "./hooks/adminContext.js";
import Admin from "./hooks/admin";
import PrivateRoute from "./components/PrivateRoute";
import GenotypePDFReport from "./components/reviewpdf/GenotypePDFReport.js";


function App() {
  const { admin, setAdmin } = Admin();

  

  return (
    <AdminContext.Provider value={{ admin, setAdmin }}>
    <Router>
      <Routes>
        <Route path="/" element={<Login/>}/>
        <Route path="/registerplate" element={<PrivateRoute><RegisterPlate /></PrivateRoute>}/>
        <Route path="/registergenotype" element={<PrivateRoute><RegisterGenotype /></PrivateRoute>}/>
        <Route path="/genotypelist" element={<PrivateRoute><GenotypeList /> </PrivateRoute>}/>   
        {/* <Route path="/pdf-report" element={<PrivateRoute><GenotypePDFReport  /></PrivateRoute>}/>   */}
      </Routes>
    </Router>
    </AdminContext.Provider>
  );
}

export default App;
