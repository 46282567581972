import React, { useContext } from 'react';
import { Input, Button, Form, Alert } from 'antd';
import logo from "../assets/logo/UniqgeneLogo.svg";
import useAuth from '../hooks/useAuth';
import { AdminContext } from '../hooks/adminContext';
import axios from "axios";
import "./Login.css"


function Login() {

  const { loginAdmin } = useAuth();
  const { admin, setAdmin } = useContext(AdminContext);
  const onFinish = async (values) => {
    await loginAdmin(values)
  }

  return (
    <div className="logincontainer">
      <div className="loginsubcontainer">
        <div className="logosubcontainer">
          <img src={logo} alt="uniqgenelogo" className='logoelement'/>
        </div>
        <div className='formsubcontainer'>
          <Form
            name="basic"
            title="Admin Login"
            initialValues={{
              remember: true,
            }}
            onFinish={onFinish}
            autoComplete="off"
          >
            <Form.Item
              name="user"
              rules={[
                {
                  required: true,
                  message: 'Lütfen kullanıcı adınızı giriniz!',
                },
              ]}
            >
              <Input placeholder="Kullanıcı adınızı giriniz..." size='large'/>
            </Form.Item>

            <Form.Item
              name="password"
              rules={[
                {
                  required: true,
                  message: 'Lütfen şifrenizi giriniz!',
                },
              ]}
            >
              <Input.Password placeholder="Şifrenizi giriniz..." size='large' />
            </Form.Item>

            

            <Form.Item>
              <Button type="primary" htmlType="submit" size='large' block='true'>
                Giriş Yap
              </Button>
            </Form.Item>

            <Form.Item>
              <Alert message="Giriş bilgilerinizi hatırlamıyorsanız 
              Uniqgene ekibi ile iletişime geçebilirsiniz." type="warning"/>
            </Form.Item>
          </Form>
        </div>
      </div>
    </div>



  )


}
export default Login