import React from "react";
import "./RegisterGenotype.css";
import Popup from "reactjs-popup";
import { Checkbox } from "antd";
import { useState, useEffect } from "react";
import HelpOutlineRoundedIcon from "@mui/icons-material/HelpOutlineRounded";
import IconButton from "@mui/material/IconButton";
import { Spin, Modal } from "antd";
import axios from "axios";
import moment from "moment";
import LabMenu from "../components/LabMenu";
import { AdminContext } from "../hooks/adminContext";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const RegisterGenotype = () => {
  const { admin } = React.useContext(AdminContext);
  const cells = [
    "0",
    "1",
    "2",
    "3",
    "4",
    "5",
    "6",
    "7",
    "8",
    "9",
    "10",
    "11",
    "12",
  ];

  const platedesign = {
    A: [],
    B: [],
    C: [],
    D: [],
    E: [],
    F: [],
    G: [],
    H: [],
  };
  const [iplatedesign, setPlatedesign] = useState(platedesign);
  const [plateid, setPlateID] = useState("");
  const [plates, setPlates] = useState([1]);
  const [plateDate, setPlateDate] = useState("Select Date");
  const [isSelected, setIsSelected] = useState();
  const [redo, setRedo] = useState(false);
  const [cellNumber, setCellNumber] = useState("");
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isSpinVisible, setIsSpinVisible] = useState(false);
  const [modalText, setModalText] = useState("Please wait...");
  const hasMissingGenotype = Object.values(iplatedesign).some(row =>
    row.some(item => !item.genotypes)
  );
  
  const buttonStyle = (genotype,redo) => {
    let background_color = "";
    if(genotype && redo === false){
      switch(genotype){
        case("F"):
        return background_color= "#3c8de8";
        case("V"):
        return background_color= "#4bab65";
        case("F/V"):
        return background_color= "#f0a254";
        case("NEG"):
        return background_color= "#f23538";
      }
    }else if(!genotype){
      background_color="#fff"
      return background_color
    }else if(redo===true){
      background_color="#AA57D4"
      return background_color
    }


  }

  const onChange = (e, genotype, item) => {
   if(e.target.checked){
    setCellNumber(item.cell_number);
    setIsSelected(genotype)
   }else{
    setIsSelected(null)
   }
    
  };

  const changeRedo = (e, item) => {
    setCellNumber(item.cell_number);
    setRedo(e.target.checked);
  };

  const getplate = async () => {
    try {
      await axios
        .post("/api2/getPlate", { phase: "PostLab" })
        .then((response) => {
          if (response.data) {
            setPlates(response.data);
          }
        });
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    getplate();
  }, []);

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    return `${date.toLocaleDateString()} ${date.toLocaleTimeString()}`;
  };

  function changePlate(event) {
    const element = plates.find(
      (element) => formatDate(element.outdate) === event.target.value
    );

    setPlateDate(formatDate(element.outdate));
    setPlatedesign(element.platedesign);
    setPlateID(element._id);
  }

  function handleSave(e) {
    e.preventDefault();
    axios
      .post("/api2/savePostPlate", {
        id: plateid,
      })
      .then((response) => {
        alert(response.data.message);
      })
      .catch((error) => {
        console.log(error);
      });
  }
  
  

  function handleSubmit(e) {
    e.preventDefault();

  
    setIsSpinVisible(true);
    axios
      .post("/api2/addRedo", {
        id: plateid,
      })
      .then((response) => {
        if (response.data.submes) {
          axios
            .post("/api2/submitPostPlate", {
              id: plateid,
            })
            .then((response) => {
              setModalText(response.data.message);
              setTimeout(() => {
                setIsSpinVisible(false);
              }, 1500);
              setPlates([1]);
              setTimeout(() => {window.location.reload(true)},800);
            })
            .catch((err) => console.log(err));
        }
      })
      .catch((error) => {
        console.log(error);
      });
      
  }

  function handleChange(e, item,closePopup) {
    console.log(isSelected)
    e.preventDefault();/* 
    if (!isSelected) { */
      axios
        .put("/api2/addCellValue", {
          id: plateid,
          redo: redo,
          genotypes: !isSelected ? item.genotypes:isSelected,
          cell_number: item.cell_number,
        })
        .then((response) => {
          if (response.data.message !== "Kuyuya genotip eklendi") {
            alert(response.data.message);
          }
          setIsSelected(null);
          setPlatedesign(response.data.data);
          getplate();
          setRedo(false);
          closePopup();
        })
        .catch((error) => {
          console.log(error);
        });
    /* } else {
      console.log(isSelected);
      axios
        .put("/api2/addCellValue", {
          id: plateid,
          redo: redo,
          genotypes: isSelected,
          cell_number: item.cell_number,
        })
        .then((response) => {
          alert(response.data.message);
          setIsSelected(null);
          setPlatedesign(response.data.data);
          getplate();
          setRedo(false);
        })
        .catch((error) => {
          console.log(error);
        });
    } */
  }


  if (admin) {
    return (
      <div className="registergenotypecontainer">
  
      
          <LabMenu />
       

        <div className="genotypelisthelp"> 
        <ToastContainer/>
          <IconButton
            aria-label="help"
            size="small"
            color="error"
            onClick={() => setIsModalVisible(true)}
          >
            <HelpOutlineRoundedIcon />
          </IconButton>
       
        </div>
        <div className="registergenotypesubcontainer1">
          <div className="selectplatesubsubcontainer">
            <label for="plates" className="plateselectiondropdowntitle">
              PCR-Plate'leri
            </label>
            <br></br>
            <select
              name="plates"
              id="plates"
              value={plateDate}
              onChange={(e) => changePlate(e)}
              className="plateselectiondropdownmenu"
              placeholder="Plate seçiniz"
            >
              <option>Tarih ve zamana göre plate seçiniz</option>
              {plates.map((e, key) => {
                return (
                  <option key={key}>
                    <strong>{formatDate(e.outdate)}</strong>
                  </option>

                );
              })}
            </select>
          </div>
          <div > 
        <p> Lütfen <strong>  beyaz </strong>   hücre bırakmamaya dikkat ediniz.  </p> 
        <p>    <strong> cyp1a2 </strong> için  <strong>  F: AA, F/V: AC, V: CC </strong> ifade eder.</p>
          </div>
        </div>

        <div className="registergenotypesubcontainer2">
          <table className="registergenotypetable">
            <thead>
              <tr>
                <th></th>
                <th>1</th>
                <th>2</th>
                <th>3</th>
                <th>4</th>
                <th>5</th>
                <th>6</th>
                <th>7</th>
                <th>8</th>
                <th>9</th>
                <th>10</th>
                <th>11</th>
                <th>12</th>
              </tr>
            </thead>
            <tbody>
            {Object.keys(iplatedesign).map((name, index) => {
                //console.log(typeof iplatedesign)
                let row = iplatedesign[name];
                //console.log(typeof row);
                return (
                  <tr className={index % 2 === 0 ? "even-row" : "odd-row"}>
                    {cells.map((cell) => {
                      if (cell === "0") {
                        return <td>{name}</td>;
                      } else {
                        let cellno = `${name}${cell}`;

                        let item = row.find(
                          (item) => item.cell_number === cellno
                        );

                        //console.log(item)
                        if (item) {
                          
                          return (
                            <td >
                              <tr>{item.swapcode}</tr>
                              <tr
                                style={{
                                  display: "flex",
                                  flexDirection: "row",
                                }}
                              >
                                {item.gene}
                                <Popup
                                  trigger={
                                    <button
                                    style= {{display: "block" ,
                                    height:"auto", 
                                    width:"auto", 
                                
                                    borderRadius:"50%", 
                                    border: "1px solid #a7b1f2", 
                                    marginLeft: "10px",
                                    fontSize:22,
                                    textAlign:"center",
                                    background: `${buttonStyle(item.genotypes,item.redo[1])}`,
                                    color:"#424040"}}
                                    >
                                      +
                                    </button>
                                  }
                                  position="bottom"
                                >
                                 {(close) => (
                                  <form
                                    class="genotypeform"
                     onSubmit={(e) => handleChange(e, item, close)} // Pass the close function
            valueProp
                    
                                  >
                                     <div>
                                      <Checkbox
                                        defaultChecked={isSelected ? (isSelected==="F"):(item.genotypes==="F")}
                                        checked={isSelected ? (isSelected==="F"):(item.genotypes==="F")}
                                        disabled={isSelected ? ("F"!== isSelected ? true:false):false}
                                        onChange={(e) => onChange(e,"F", item)}
                                      >
                                       <label for="F">F</label>
                                      </Checkbox>
                                      <Checkbox
                                        defaultChecked={isSelected ? (isSelected==="F/V"):(item.genotypes==="F/V")}
                                        checked={isSelected ? (isSelected==="F/V"):(item.genotypes==="F/V")}
                                        disabled={isSelected ? ("F/V"!== isSelected ? true:false):false}
                                        onChange={(e) => onChange(e,"F/V", item)}
                                      >
                                       <label for="F/V">F/V</label>
                                      </Checkbox>
                                      <Checkbox
                                        defaultChecked={isSelected ? (isSelected==="V"):(item.genotypes==="V")}
                                        checked={isSelected ? (isSelected==="V"):(item.genotypes==="V")}
                                        disabled={isSelected ? ("V"!== isSelected ? true:false):false}
                                        onChange={(e) => onChange(e,"V", item)}
                                      >
                                       <label for="V">V</label>
                                      </Checkbox>
                                      <Checkbox
                                        defaultChecked={isSelected ? (isSelected==="NEG"):(item.genotypes==="NEG")}
                                        checked={isSelected ? (isSelected==="NEG"):(item.genotypes==="NEG")}
                                        disabled={isSelected ? ("NEG"!== isSelected ? true:false):false}
                                        onChange={(e) => onChange(e,"NEG", item)}
                                      >
                                       <label for="NEG">NEG</label>
                                      </Checkbox> 
                                      
                                    </div> 
                                    <div>
                                      <Checkbox
                                        defaultChecked={item.redo[1] === true} //değiştirildi
                                        onChange={(e) => changeRedo(e, item)}
                                      >
                                       <label for="Redo">Tekrar</label>
                                      </Checkbox>
                                      <input type="submit" value="Kaydet" />
                                    </div>
                                  </form>
                                  )}
                                </Popup>
                              </tr>
                            </td>
                          );
                        } else {
                          return <td></td>;
                        }
                      }
                    })}
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
        <div className="buttonsubcontainer">
          <button
            className="registerplatebutton"
            onClick={(e) => handleSave(e)}
          >
           Düzenlemeleri kaydet
          </button>
          <Popup
        trigger={
          <button className="submitplatebutton"  disabled={isSpinVisible} >
           Genotip Listesine Gönder
          </button>
        }
        modal
        closeOnDocumentClick
        contentStyle={{color: "#000" ,width: '400px', height:"auto", borderRadius:"20px", backgroundColor: "#fc966a", fontSize: "24px", textAlign: "center"}}
      >
        {(close) => (
          <div className="confirmation-popup">
          {hasMissingGenotype ? (
              <p>Lütfen tüm hücrelerdeki genotiplerin işaretlenip kaydedildiğinden emin olduktan sonra tekrar deneyiniz !</p>
            ) : (
              <>
            <p>Plate'e girdiğiniz genotip sonuçları genotip listesine gönderilecek. Emin misiniz?</p>
            <button style={{ color: "#423dd9",fontSize: "20px" ,margin: "20px"}} onClick={(e) => { handleSubmit(e); close(); }}>Evet</button>
            <button style={{color: "#ed3b5f", fontSize: "20px",  margin: "20px"}}  onClick={close}>Hayır</button>
            </>
            )}
          </div>
        )}
      </Popup>
        </div>
        <Modal
          className="helpmodal"
          title="PCR-Plate Kullanımı"
          open={isModalVisible}
          width={800}
          closable={true}
          onCancel={() => setIsModalVisible(false)}
          okButtonProps={{ style: { display: "none" } }}
          cancelButtonProps={{ style: { display: "none" } }}
        >
          <div style={{ textAlign: "left" }}>
            <strong>1)</strong> PCR-Plate aşamasında olan plate’leri sol üstte
            bulunan, aşağı doğru açılan menü içerisinde PCR-hazırlık’taki Gönder
            butonuna basıldığı zamana göre bulabilirsiniz.
            <br></br>
            <strong>2)</strong> Bu aşamada hücrelerde yer değişikliği yapılamaz.
            Hücrelerin yerlerinde sorun yaşıyorsanız lütfen Uniqgene Ekibine ulaşın.
            <br></br>
            <strong>3)</strong> Hücrelerde bulunan <strong>“+”</strong>{" "}
            işaretine bastığınızda <strong>genotip</strong> ve{" "}
            <strong>tekrar girilme (saptanamayan örnekler için) </strong>
            bilgisini girmeniz için bir kutucuk çıkacaktır.
            <br></br>
            <strong>***</strong> Genotip girişi: <strong>F ,V ,F/V ,NEG</strong>{" "}
            genotiplerinden birini seçip tekrar girilecek ise{" "}
            <strong>Tekrar</strong> kutucuğunu işaretlemeli ve genotip girişi için
            açılan kutu içerisinde bulunan <strong>Düzenlemeleri Kaydet</strong> butonuna
            basmalısınız. Butona bastığınızda gelen uyarıları onaylayarak
            genotip girişini yaptığınızı teyit edebilirsiniz.
            <br></br>
            <strong>**</strong>
            <strong>Düzenlemeleri Kaydet</strong> butonuna <u>basılmadığında</u> girdiğiniz
            değerler kaybolacaktır.
            <br></br>
            <strong>**</strong>Yeni talep edilecek (müşteriden tekrar örnek
            istenecek) olduğunda <strong>Tekrar kutucuğu işaretlenmemelidir.</strong> 
            <br></br>
            <strong>4)</strong> Hücrelerin genotip değerinin var olup olmadığını
            hücre renklerinden anlayabilirsiniz.
      
           
            <strong>5)</strong> Genotip girişleri tamamlandığında önce{" "}
            <strong>Kaydet</strong> ardından <strong>Gönder</strong> butonuna
            basınız. Yüklenme ekranı bittiğinde genotip listesini kontrol
            edebilirsiniz.
            <br></br>
            <strong>**</strong> Yanlış girilen ya da boş girilme durumlarında lütfen Uniqgene ekibine başvurunuz.
          </div>
        </Modal>
        <Modal
          title="PostLab plate submit"
          open={isSpinVisible}
          okButtonProps={{ style: { display: "none" } }}
          cancelButtonProps={{ style: { display: "none" } }}
        >
          <p>{modalText}</p>
          <div style={{ textAlign: "center" }}>
            <Spin size="large" />
          </div>
        </Modal>
      </div>
    );
  }
};

export default RegisterGenotype;
