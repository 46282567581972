import React, { useRef } from "react";
import { useState, useEffect } from "react";
import { Button, Modal } from "antd";
import "./GenotypeList.css";
import axios from "axios";
import HelpOutlineRoundedIcon from "@mui/icons-material/HelpOutlineRounded";
import IconButton from "@mui/material/IconButton";
import CircularProgress from "@mui/material/CircularProgress";
import LabMenu from "../components/LabMenu"
import { AdminContext } from "../hooks/adminContext";
import Popup from "reactjs-popup";
import DataTable , { createTheme } from "react-data-table-component";
import Tippy from '@tippyjs/react';
import 'tippy.js/dist/tippy.css';
import GenotypePDFReport from "../components/reviewpdf/GenotypePDFReport"

const customOptions = {
  rowsPerPageText: 'Satır Sayısı:',
  rangeSeparatorText: 'arası',
  selectAllRowsItem: true,
  selectAllRowsItemText: 'Hepsi',
};
const genotypelist = [
  {
    swapcode: "Loading",
    actn3: "Loading",
    actn3_redo:null,
    ppara: "Loading",
    ppara_redo:null,
    ppargc1a: "Loading",
    ppargc1a_redo:null,
    bdnf: "Loading",
    bdnf_redo:null,
    col1a1: "Loading",
    col1a1_redo:null,
    il6: "Loading",
    il6_redo:null,
    adrb2: "Loading",
    adrb2_redo:null,
    nos3: "Loading",
    nos3_redo:null,
    hif1a: "Loading",
    hif1a_redo:null,
    col5a1: "Loading",
    col5a1_redo:null,
    mct1: "Loading",
    mct1_redo:null,
    comt: "Loading",
    comt_redo:null,
    cyp1a2: "Loading",
    cyp1a2_redo:null,
    ready_count: "Loading",
    ready: "Loading",
  },
];


createTheme("solarized", {
  text: {
    primary: "#268bd2",
    secondary: "#2aa198"
  },
  background: {
    default: "white"
  },
  
  divider: {
    default: "white"
  },
  striped: {
    default: "#c8e0fa", // İkinci satır rengi
  },

});
const customStyles = {
 
  headCells: {
      style: {
          paddingLeft: '2px', 
          paddingRight: '2px',
          fontSize: "15px",
          fontWeight: "600",
          color: "#1c2736"
      },
  },

  cells: {
      style: {
          paddingLeft: '8px', 
          paddingRight: '8px',
          fontSize: "14px",
          fontWeight: "500",
          color: "#1c2736",
          '&:not(:last-of-type)': {
          			borderRightStyle: 'solid',
       		borderRightWidth: '1px',
           			borderRightColor: "#cdcdd1"
           			},
      },
  },
  swapcodeCell: {
    style: {
      backgroundColor: '#4caf50', // Yeşil arka plan rengi
      color: 'white', // Beyaz metin rengi
      padding: '10px',
    
      fontSize: '14px',
      fontWeight: '500',
    },
  },
};
const getConditionalCellStyles = (redoProperty) => [
  {
    when: row => row[redoProperty] === null,
    style: {
      backgroundColor: '', 
    },
  },
  {
    when: row => row[redoProperty] !== null && row[redoProperty] !== undefined && row[redoProperty] !== 0,
style: {
  backgroundColor: '#c15cf2',
  color: 'white',
},

  },
];

const GenotypeList = () => {
  const [isReportsPopupVisible, setIsReportsPopupVisible] = useState(false);
  const [sportReportPopupvisible, setsportReportPopupvisible] = useState(false);
  const [selectedRowData, setSelectedRowData] = useState(null);
  const[selectedid,setSelectedid] = useState(null);
  const [rows, setRows] = useState(genotypelist);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const { admin } = React.useContext(AdminContext)
  const [customerInfo, setCustomerInfo] = useState(null);
  const [isPreparing, setIsPreparing] = useState(null);
  const sportReportpopup= () => {
    setsportReportPopupvisible(true);
    setIsReportsPopupVisible(false);
  }


  // rowdaki müşterinin name ve birthdate bilgilerini almak için selectedid değiştikçe bilgiler useeffect ile güncelleniyor ; 
  useEffect(() => {
    const fetchCustomerInfo = async (barcode) => {
      try {
        const response = await axios.post("/api2/getBirthAndName", {
          barcode_number: barcode.swapcode,
        });
  
        if (response.status === 200) {
          const { name, birth_date } = response.data;
          setCustomerInfo({ name, birthDate: birth_date });
        } else {
          console.error("Error fetching customer information:", response.data.message);
        }
      } catch (error) {
        console.error("Error fetching customer information:", error.message);
      }
    };
  
    if (isReportsPopupVisible && selectedid) {
      setCustomerInfo(null);
  
      fetchCustomerInfo(selectedid);
    }
  }, [isReportsPopupVisible, selectedid]);

const openReportsPopup = (rowData) => {
  const formattedRowData = {
    actn3: rowData.actn3,
    ppara: rowData.ppara,
    ppargc1a: rowData.ppargc1a,
    bdnf : rowData.bdnf,
    col1a1: rowData.col1a1,
    il6: rowData.il6,
    adrb2: rowData.adrb2,
    nos3: rowData.nos3,
    hif1a: rowData.hif1a,
    col5a1: rowData.col5a1,
    mct1: rowData.mct1,
    comt: rowData.comt,
    cyp1a2: rowData.cyp1a2
  };
  const barcodedata = {
    swapcode: rowData.swapcode
  }


  setSelectedRowData(formattedRowData);
  setSelectedid(barcodedata)
  setIsReportsPopupVisible(true);

};


const handleSave = (row, pack) => {
  setIsPreparing(row.swapcode);
  if(admin.name === 'Lab'){
    if(pack === "sport"){
      axios
      .post("/api2/readyReport", {
        swapcode:row.swapcode
      })
      .then((response) => {
        setIsPreparing(null);
        alert(response.data.message);
        window.location.reload(true);
      })
      .catch((err) => console.log(err));
    }else{
      axios
      .post("/api2/readyReport2", {
        swapcode: row.swapcode,
      })
      .then((response) => {
        setIsPreparing(null); 
        alert(response.data.message);
        window.location.reload(true);
      })
      .catch((err) => {
        console.log(err);
        setIsPreparing(null);
      });
    }
  }else{
    if(pack === "sport"){
      axios
      .post("/api2/sportSend", {
        swapcode:row.swapcode
      })
      .then((response) => {
        setIsPreparing(null);
        alert(response.data.message);
        window.location.reload(true);
      })
      .catch((err) => console.log(err));
    }else{
      axios
      .post("/api2/nutritionSend", {
        swapcode: row.swapcode,
      })
      .then((response) => {
        setIsPreparing(null); 
        alert(response.data.message);
        window.location.reload(true);
      })
      .catch((err) => {
        console.log(err);
        setIsPreparing(null);
      });
    }
  }
}


  useEffect(() => {
    const getGenotypes = async () => {
      try {
        let apiUrl = "/api2/getGenotypes";
        if (admin.name !== 'Lab') {
          apiUrl = "/api2/getGenotypesothers";
        }
        const response = await axios.get(apiUrl);
  
        if (response.data) {
          const groupedRows = response.data.reduce((grouped, row) => {
            const swapcode = row.swapcode;
            if (!grouped[swapcode]) {
              grouped[swapcode] = {
                ...row,
                ready_count_list: [row.ready_count],
                ready_list: [row.ready],
              };
            } else {
              Object.assign(grouped[swapcode], row);
              grouped[swapcode].ready_count_list.push(row.ready_count);
              grouped[swapcode].ready_list.push(row.ready);
            }
            return grouped;
          }, {});
  
          const finalRows = Object.values(groupedRows).map(groupedRow => {
// readylerin 2 sinden birinde  0 varsa hazır değil !!!
            const isAllReadyOne = groupedRow.ready_list.every(value => value === 1);
            groupedRow.ready = isAllReadyOne ? 1 : 0;
            return groupedRow;
          });
  
          setRows(finalRows);
        }
      } catch (err) {
        console.log(err);
      }
    };
  
    getGenotypes();
  }, []);
  
  
  
  

  const columns = [
    {
      name: 'Swab Kodu',
     selector: row => row.swapcode,
      id: "swapcode",
    center: true,
    style:{
      color: "#110d63",
      fontWeight: "600",
  },
  cell: row => (
    <Tippy content={row.swapcode}>
<span style={row.ready === 1 ? customStyles.swapcodeCell.style : {}}>
        {row.swapcode}
      </span>
    </Tippy>
  ),
 
    },
    {
      name: 'ACTN3',
      selector: row => row.actn3,
      conditionalCellStyles: getConditionalCellStyles('actn3_redo'),
      center: true,
      cell: row => (
        <Tippy content={row.swapcode}>
          <span>{row.actn3}</span>
        </Tippy>
      ),
    },
    {
      name: 'PPARa',
      selector: row => row.ppara,
      conditionalCellStyles: getConditionalCellStyles('ppara_redo'),
      center: true,
      cell: row => (
        <Tippy content={row.swapcode}>
          <span>{row.ppara}</span>
        </Tippy>
      ),
    },
    {
      name: 'PPARGC1a',
      selector: row => row.ppargc1a,
      conditionalCellStyles: getConditionalCellStyles('ppargc1a_redo'),
      center: true,
      cell: row => (
        <Tippy content={row.swapcode}>
          <span>{row.ppargc1a}</span>
        </Tippy>
      ),
    },
    {
      name: 'BDNF',
      selector: row => row.bdnf,
      conditionalCellStyles: getConditionalCellStyles('bdnf_redo'),
      center: true,
      cell: row => (
        <Tippy content={row.swapcode}>
          <span>{row.bdnf}</span>
        </Tippy>
      ),
    },    
    {
      name: 'COL1A1',
      selector: row => row.col1a1,
      conditionalCellStyles: getConditionalCellStyles('col1a1_redo'),
      center: true,
      cell: row => (
        <Tippy content={row.swapcode}>
          <span>{row.col1a1}</span>
        </Tippy>
      ),
    },
    {
      name: 'IL6',
      selector: row => row.il6,
      conditionalCellStyles: getConditionalCellStyles('il6_redo'),
      center: true,
      cell: row => (
        <Tippy content={row.swapcode}>
          <span>{row.il6}</span>
        </Tippy>
      ),
    },
    
    {
      name: 'ADRB2',
      selector: row => row.adrb2,
      conditionalCellStyles: getConditionalCellStyles('adrb2_redo'),
      center: true,
      cell: row => (
        <Tippy content={row.swapcode}>
          <span>{row.adrb2}</span>
        </Tippy>
      ),
    },
    {
      name: 'NOS3',
      selector: row => row.nos3,
      conditionalCellStyles: getConditionalCellStyles('nos3_redo'),
      center: true,
      cell: row => (
        <Tippy content={row.swapcode}>
          <span>{row.nos3}</span>
        </Tippy>
      ),
    },
    {
      name: 'HIF1A',
      selector: row => row.hif1a,
      conditionalCellStyles: getConditionalCellStyles('hif1a_redo'),
      center: true,
      cell: row => (
        <Tippy content={row.swapcode}>
          <span>{row.hif1a}</span>
        </Tippy>
      ),
    },
    {
      name: 'COL5A1',
      selector: row => row.col5a1,
      conditionalCellStyles: getConditionalCellStyles('col5a1_redo'),
      center: true,
      cell: row => (
        <Tippy content={row.swapcode}>
          <span>{row.col5a1}</span>
        </Tippy>
      ),
    },
    {
      name: 'MCT1',
      selector: row => row.mct1,
      conditionalCellStyles: getConditionalCellStyles('mct1_redo'),
      center: true,
      cell: row => (
        <Tippy content={row.swapcode}>
          <span>{row.mct1}</span>
        </Tippy>
      ),
    },
    {
      name: 'COMT',
      selector: row => row.comt,
      conditionalCellStyles: getConditionalCellStyles('comt_redo'),
      center: true,
      cell: row => (
        <Tippy content={row.swapcode}>
          <span>{row.comt}</span>
        </Tippy>
      ),
    },
    {
      name: 'CYP1A2',
      selector: row => row.cyp1a2,
      conditionalCellStyles: getConditionalCellStyles('cyp1a2_redo'),
      center: true,
       cell: row => (
         <Tippy content={row.swapcode}>
           <span>{row.cyp1a2}</span>
         </Tippy>
       ),
    },
    {
      name: 'Spor Tekrar',
      selector: row => (row.ready_count_list && row.ready_count_list.length > 0 ? row.ready_count_list[0] === 0 ? 'Tekrar Edilmedi' : row.ready_count_list[0] !== null ? row.ready_count_list[0] : 'Tekrar Edilmedi' : 'Tekrar Edilmedi'),

      center: true,
      sticky: true,
      cell: row => (
        <>
            <Tippy content={row.swapcode}>
            <span style={{ textAlign: "center" }}>
  {row.ready_count_list && row.ready_count_list.length > 0 ?
    row.ready_count_list[0] === 0 ? 'Tekrar Edilmedi' :
      row.ready_count_list[0] !== null ? row.ready_count_list[0] : 'Tekrar Edilmedi' :
    'Tekrar Edilmedi'
  }
</span>
          </Tippy>
        </>
      ),
    },
    {
      name: 'Kafein Tekrar',
      selector: row => (row.ready_count_list && row.ready_count_list.length > 1 ? row.ready_count_list[1] : ''),
      center: true,
      sticky: true,
      cell: row => (
        <>
          <Tippy content={row.swapcode}>
          <span style={{ textAlign: "center" }}>
  {row.cyp1a2 === undefined  ?
    '' :
    row.ready_count_list && row.ready_count_list.length > 1 ?
      row.ready_count_list[1] === 0 ? 'Tekrar Edilmedi' :
        row.ready_count_list[1] !== null ? row.ready_count_list[1] : 'Tekrar Edilmedi' :
      'Tekrar Edilmedi'
  }
</span>
   </Tippy>
        </>
      ),
    },
    
    {
      name: 'Durum',
      selector: row => row.ready,
      cell: row => (
        <>
          <Tippy content={row.swapcode}>
            <span>{row.ready === 1 ? "Hazır" : "Hazır Değil"}</span>
          </Tippy>
        </>
      ),
      center: true,
      sticky: true,
     
    },
    {
      name: 'Önizleme',
      selector: row => row.swapcode,
      cell: (row) => (
        <Button
          type="primary"
          size="small"
          onClick={() => openReportsPopup(row)}
          disabled={row.ready !== 1}
        >
          Göster
        </Button>
      ),
      center: true,
      sticky: true,
    },
    {
      name: 'Spor',
      selector: row => row.swapcode,// Assuming this is the unique identifier
      center: true,
      sticky: true,
      cell: row => {
        if (row.ready !== 1) {
          // Status "Ready" değilse, buton devre dışı bırakılır.
          return (
            <Button type="primary" size="small" danger disabled>
            Gönder
            </Button>
          );
        }
    
        // Genotip sonuçları
        const genotypes = [
          row.actn3, row.ppara, row.ppargc1a, row.bdnf, row.col1a1, 
          row.il6, row.adrb2, row.nos3, row.hif1a, row.col5a1, 
          row.mct1, row.comt
        ];
    
        // Negatif genotip sayısı
        const negativeCount = genotypes.filter(genotype => genotype === "NEG").length;
    
        // NOS3 ve HIF1a kontrolü
        const isNos3Hif1aValid = !(row.nos3 === "NEG" && row.hif1a === "NEG");
    
        // BDNF ve COMT kontrolü
        const isBdnfComtValid = !(row.bdnf === "NEG" && row.comt === "NEG");
    
        // Genotipler tanımlı kontrolü
        const isGenotypeDefined = genotypes.every(genotype => genotype !== "Loading" && genotype !== undefined);

    
        const isDisabled =
          negativeCount > 2 ||
          !isNos3Hif1aValid ||
          !isBdnfComtValid ||
          !isGenotypeDefined;
    
        return (
          <Popup
            trigger={
              <Button
                type="primary"
                size="small"
                danger
                disabled={isDisabled && admin.name === "Lab"}
              >
             {isPreparing === row.swapcode ? <CircularProgress size={20} color="inherit" /> : "Gönder"}
              </Button>
          }
          modal
          closeOnDocumentClick
          contentStyle={{ color: "#515244", width: '400px', height: "200px", backgroundColor: "#ddbbed", fontSize: "24px", textAlign: "center" }}
        >
          {(close) => (
            <>
        {admin.name === "Lab" && (
            <div className="confirmation-popup">
           
              <p>Sonuçlar müşteriye rapor olarak sunulacak. Emin misiniz?</p>
              <button style={{ color: "#423dd9", fontSize: "20px", margin: "20px" }} onClick={() => { handleSave(row, "sport"); close(); }}>Evet</button>
              <button style={{ color: "#ed3b5f", fontSize: "20px", margin: "20px" }} onClick={close}>Hayır</button>
         
            </div>
            )}
            {admin.name !== "Lab" && (
              <div className="confirmation-popup">
              <p>Sonuçlar müşteriye gün içerisinde iletilecektir.</p>
              <button style={{ color: "#423dd9", fontSize: "20px", margin: "20px" }} onClick={() => { handleSave(row, "sport"); close(); }}>Evet</button>
              <button style={{ color: "#ed3b5f", fontSize: "20px", margin: "20px" }} onClick={close}>Hayır </button>
         
            </div>
        )}
              </>
          )}
        </Popup>
        );
      },
    },
 
    {
      name: 'Kafein',
      selector: row => row.swapcode,
      center: true,
      sticky: true,
      cell: row => (
        <Popup
          trigger={
            <Button type="primary" size="small" danger 
            //Eğer redosu varsa(null değilse, tekrar girilecekse) veya cypa1a2 geni tanımlı değilse veya gen Negatifse rapor gönderimi yapamayacak
            disabled={row.cyp1a2_redo !== null || row.cyp1a2 === undefined || row.cyp1a2 === "NEG" }>
         {isPreparing === row.swapcode ? <CircularProgress size={20} color="inherit" /> : "Gönder"}
      </Button>
          }
          modal
          closeOnDocumentClick
          contentStyle={{ color: "#515244", width: '400px', height: "200px", backgroundColor: "#ddbbed", fontSize: "24px", textAlign: "center" }}
        >
          {(close) => (
            <>
        {admin.name === "Lab" && (
            <div className="confirmation-popup">
              <p>Sonuçlar müşteriye rapor olarak sunulacak. Emin misiniz?</p>
              <button style={{ color: "#423dd9", fontSize: "20px", margin: "20px" }} onClick={() => { handleSave(row, "nutrition"); close(); }}>Evet</button>
              <button style={{ color: "#ed3b5f", fontSize: "20px", margin: "20px" }} onClick={close}>Hayır</button>
            </div>
            )}
            {admin.name !== "Lab" && (
              <div className="confirmation-popup">
              <p>Sonuçlar müşteriye gün içerisinde iletilecektir. Onaylıyor musunuz?</p>
              <button style={{ color: "#423dd9", fontSize: "20px", margin: "20px" }} onClick={() => { handleSave(row, "nutrition"); close(); }}>Evet</button>
              <button style={{ color: "#ed3b5f", fontSize: "20px", margin: "20px" }} onClick={close}>Hayır</button>
              </div>
        )}
              </>
        
          )}
        </Popup>
        
      ),
    },
    {
      name: 'SporRaporu',
      selector: row => row.swapcode,
      center: true,
      sticky: true,
      hide: admin.name !== 'Lab',
      cell: row => (
        <button
          style={{ padding: "4px", borderRadius: "6px", background: "#5a585c", color: "white" }}
          onClick={() => { handleSave(row, "sport") }}
        >
          force
        </button>
      ),
    },
  ];

  const dataTableColumns = columns.filter(column => !column.hide);

  if(admin){
    return (
      <div className="genotypelistcontainer">
       <LabMenu/>
        <div className="genotypelisthelp">
          <IconButton
            aria-label="help"
            size="small"
            color="error"
            onClick={() => setIsModalVisible(true)}
          >
            <HelpOutlineRoundedIcon />
          </IconButton>
        </div>
         <div style={{ marginTop: "5%", overflowX: "auto" }}>
        <DataTable 
        columns={dataTableColumns}
            data={rows}
            pagination
       
  selectAllRowsItem={true} 
      paginationPerPage={30} 
      paginationComponentOptions={customOptions}
            highlightOnHover
            striped
		pointerOnHover
    theme="solarized"
    customStyles={customStyles} 
    fixedHeader
         />
         
      </div>
      <Modal
        title= "Rapor Önizlemeleri"
        visible={isReportsPopupVisible}
        closable={true}
        okButtonProps={{ style: { display: "none" } }}
        cancelButtonProps={{ style: { display: "none" } }}
        onCancel={() => {
          setSelectedRowData(null);
          setSelectedid(null) 
          setIsReportsPopupVisible(false);
          setCustomerInfo(null)
        }}
      >
   
    <p> Lütfen önizlemesini görmek istediğiniz paketi seçiniz.</p>
    <div style={{display: "flex", alignItems: "center", justifyContent: "space-around", padding:"30px"}}> 
        <Button type="primary" onClick={() => sportReportpopup()} >
          Spor Raporu
        </Button>
        {/* <Button type="primary" >
          Nutrition Report
        </Button> */}
   
        </div>
        
      </Modal>
      <Modal
      
          visible={sportReportPopupvisible}
          
          closable={true}
          onCancel={() => setsportReportPopupvisible(false)}
          okButtonProps={{ style: { display: "none" } }}
          cancelButtonProps={{ style: { display: "none" } }}
          width="70%"  
          bodyStyle={{ height: '100vh' }} 
          style={{ top: 0, marginTop: 0 }}
          > 
      <div style={{height: "100%" , width: "100%"}}> 
<GenotypePDFReport  selectedRowData={selectedRowData}
  swapcode={selectedid?.swapcode}
  name={customerInfo?.name}
  birthDate={customerInfo?.birthDate}  />

        </div>
      </Modal>
        <Modal
          title="Genotip Listesi Kullanımı"
          visible={isModalVisible}
          width={800}
          closable={true}
          onCancel={() => setIsModalVisible(false)}
          okButtonProps={{ style: { display: "none" } }}
          cancelButtonProps={{ style: { display: "none" } }}
        >
          <div style={{ textAlign: "left" }}>
            <strong>1)</strong>Tabloda örneklerin genotip değerleri, Tekrar
            girilme sayıları <strong>(Tekrar)</strong>, Örneklerin raporlama için  durumları{" "}
            <strong>(Durum)</strong>,  {" "}
            Rapor önizlemesi için <strong>(Göster)</strong> butonu ve raporlar için gönderme seçeneği{" "}
            <strong>(Gönder)</strong> butonları bulunmaktadır.
            <br></br>
            <strong>2)</strong>Genotip değeri <strong>yazmaması</strong> genotip
            değerinin girilmediğini gösterir. Girildiği halde gözükmüyorsa lütfen Uniqgene ekibine başvurunuz. 
            <br></br>
            
            <strong>3)</strong>Tekrar girilme sayısını kontrol ederek satış
            ekibine yeni örnek talebinde bulunabilirsiniz.<br></br>
            <strong>4)</strong>Genotipleri girilmiş, tekrar örnek istenmeyecek ve
            durumu Hazır olan örnekler raporlanma için aynı satırda
            bulunan Gönder butonuna basılarak raporlanmaya gönderilir.
            <br></br>
            <strong>5)</strong>Rapor oluşturma aşamasında parametrelerin kendine özel şartları vardır ve Toplamda negatif genotip sayısı 2'yi geçmemelidir. <strong> Gönder</strong> butonları bu şartlara göre kapalı olabilir. Eğer tekrar sayısı çok fazla, genotipleri hala negatif ve  Gönder butonu kapalı bir durumla karşılaşırsanız lütfen Uniqgene ekibi ile iletişime geçiniz.
            <br></br>
            <strong>**Hazır değil</strong> durumunda olan örnekler PCR-plate
            aşamasında tekrar girilecek gen içeren  örneklerdir.
          </div>
        </Modal>
        
      </div>
    );

  }
  
};

export default GenotypeList;
