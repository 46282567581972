const info = {
    "actn3": {
        "info": "İnsanda alfa-aktinin-3 proteinin kodlanmasından sorumlu gen bölgesidir. Çoğunlukla hızlı kasılan Tip II kas fiberlerinde üretilir. ",
        "genotype": {
            "RR": "Bu genotip, homozigot iki kopya hızlı kasılma alelini taşıyan bireyleri ifade eder. Tip 2 fiber üretim oranı yüksek eğilim gösterir. Bu durumda, kişiler genellikle daha yüksek patlayıcı hız ve güce sahip olma eğilimindedir. RR genotipi taşıyanlar, sprint, kısa mesafe koşuları, güreş, artistik jimnastik gibi hız ve güç gerektiren sporlarda avantajlı olabilirler. Anaerobik egzersizlere daha olumlu yanıt verme eğilimindedirler.",
            "RX": "Bu genotip, bir hızlı kasılma aleli ve bir yavaş kasılma aleli taşıyan bireyleri ifade eder. RX genotipi, genel olarak hızlı kasılma alelinin üstünlüğü nedeniyle yüksek düzeyde performans gösterme eğilimindedir, ancak RR genotipine kıyasla biraz daha az hız ve güce sahip olabilirler.",
            "XX": "Bu genotip, homozigot (iki kopya) yavaş kasılma alelini temsil eder. Yavaş kasılma aleli, dayanıklılık gerektiren özellikle uzun süreli aktivitelerde daha iyi performans sağlamaktadır. XX genotipine sahip bireyler, futbol, basketbol ve voleybol gibi takım branşlarının yanı sıra; uzun mesafe yüzme, maraton gibi dayanıklılık gerektiren branşlarda daha verimli performans gösterebilirler.",
            "Negatif": "Bu gen bölgesi için herhangi bir sonucunuz yok. Bunun nedeni, belirli popülasyonlarda henüz tanımlanmamış varyantların varlığı olabilir."
        }
    },

    "bdnf": {
        "info": "BDNF beyinde,  yeni nöral bağlantıların kurulması ve nöral hücrelerin korunmasını sağlayan, çeşitli etkilerden sonra salınan bir kimyasaldır. ",
        "genotype": {
            "GG": "Bu genotip, homozigot (iki kopya) G alelini taşıyan bireyleri ifade eder. BDNF protein üretimi ve salınımı genellikle daha yüksek seviyelerde olabilir. Bu durum, öğrenme, hafıza ve nöral plastisite üzerinde olumlu etkiler sağlar. Daha hızlı ve kolay öğrenme yeteneği ile yüksek hafıza performansı ile ilişkilendirilebilir.",
            "AG": "Bu genotip, bir kopya G alleli ve bir kopya A aleli taşıyan bireyleri ifade eder. Genel olarak, GG genotipine göre daha düşük, AA genotipine göre daha yüksek BDNF üretimi gözlemlenebilir. Bu durum, öğrenme ve hafıza üzerinde ortalama düzeyde etkinlik gösterir.",
            "AA": "Bu genotip, homozigot (iki kopya) A allelini taşıyan bireyleri ifade eder. Bu genotipin BDNF üretimi ve salınımı GG genotipine göre daha düşük olabilir.",
            "Negatif": "Bu gen bölgesi için herhangi bir sonucunuz yok. Bunun nedeni, belirli popülasyonlarda henüz tanımlanmamış varyantların varlığı olabilir."
        }
    },

    "comt": {
        "info": "COMT, dopamin, adrenalin, nöradrenalin gibi katekolamid grubu nöral sinyal iletiminden sorumlu kimyasalları parçalayarak beyinde seviyesini control eden enzimdir. ",
        "genotype": {
            "AA": "Bu genotip, iki kopya (homozigot) A alelini taşıyan bireyleri ifade eder. Bu durumda, COMT enzimi daha düşük aktiviteye sahip olabilir ve dopamin ve noradrenalin gibi nörotransmitterlerin yıkımı yavaşlayabilir. Bu, dopamin seviyelerinin ve sinirsel uyarılmanın artmasına neden olabilir. AA genotipi, yaratıcı düşünme, yüksek motivasyon ve girişkenlik gibi durumlarla ilişkilendirilir.",
            "AG": "Bu genotip, bir kopya G aleli ve bir kopya A aleli taşıyan bireyleri ifade eder. GA genotipi, orta düzeyde COMT aktivitesine sahip olabilir. Dopamin yıkımı daha dengeli bir şekilde gerçekleşebilir, bu da motive olma ile stres altında karar verme becerileri arasında bir tür denge sağlayabilir. Bu genotipin çok yönlü düşünebilme ve işlevsellik ile ilişkilendirildiği gözlemlenmiştir.",
            "GG": "Bu genotip, iki kopya G alelini taşıyan bireyleri temsil eder. GG genotipinde, COMT enzimi daha yüksek aktiviteye sahip olabilir, dolayısıyla dopamin ve noradrenalin yıkımı daha hızlı olabilir. Bu durum, düşük seviyede dopamin ve noradrenalin birikimine yol açabilir ve daha iyi odaklanma ve stres altında karar verme yetenekleri ile ilişkilendirilebilir.",
            "Negatif": "Bu gen bölgesi için herhangi bir sonucunuz yok. Bunun nedeni, belirli popülasyonlarda henüz tanımlanmamış varyantların varlığı olabilir."
        }
    },

    "ppara": {
        "info": "Vücutta yağ metabolizmasını ve enerji regülasyonunu kontrol eden protein ailesine ait bir reseptördür. ",
        "genotype": {
            "CC": "Yüksek oranda patlayıcı güce dayalı antrenmanlarda yüksek performans gösterebilir. Dolayısıyla, bu genotipe sahip bireylerin kaslarını enine büyütmeleri kolaylaşır. Ancak, yağ yıkım seviyesinde azalma görülebilir.",
            "CG": "Patlayıcı güce dayalı antrenmanlarda daha hızlı gelişme gösterilebilir. Yağ yıkımı ortalama seviyededir.",
            "GG": "Dayanıklılık antrenmanlarına daha iyi yanıt verilebilir, yavaş kasılan Tip I kas türüne sahip olma eğilimi daha yüksektir. Kasların maksimum oksijen tüketimi daha yüksektir ve yağ yıkım seviyesinde iyileşme görülebilir.",
            "Negatif": "Bu gen bölgesi için herhangi bir sonucunuz yok. Bunun nedeni, belirli popülasyonlarda henüz tanımlanmamış varyantların varlığı olabilir."
        }
    },

    "ppargc1a": {
        "info": "PPARa gibi Vücutta yağ metabolizmasını ve enerji regülasyonunu kontrol eden protein ailesine ait proteindir.",
        "genotype": {
            "AA": "Tip II fiber üretimine eğilimli olunabilir, kanda yağ asitleri seviyesinde artış ve aynı zamanda yağ yakımında azalış gözlemlenebilir. Patlayıcı güce dayalı antrenmanlara daha iyi yanıt verilebilir. ",
            "AG": "Dayanıklılık egzersizleriyle, daha yüksek oranda, yavaş kasılan Tip I kas grubu oranında artış meydana gelebilir. Kanda artan yağ asitleri seviyesi ve yağ metabolizması dengesinde azalma gözlemlenebilir. ",
            "GG": "Dayanıklılık sporlarına daha iyi yanıt verilebilir ve dayanıklılık egzersizleriyle, kas türünün Tip I’e doğru dönüşmesi beklenebilir. Oksijen kapasitesi ve yağ yakımı, dayanıklılık antrenman türüne daha iyi yanıt verir. Aerobik kapasitede artış gözlenebilir.",
            "Negatif": "Bu gen bölgesi için herhangi bir sonucunuz yok. Bunun nedeni, belirli popülasyonlarda henüz tanımlanmamış varyantların varlığı olabilir."
        }
    },

    "adrb2": {
        "info": "ADRB2 vücutta hem adrenaline yanıt verilmesini sağlayan, dolaşım sistemi ve sinir sisteminde rol alan aynı zamanda vücudun yağ metabolizmasında etkin bir reseptördür. ",
        "genotype": {
            "GG": "Bu varyant, bronşların genişlemesine olumlu yanıt verilmesini sağlar. Bu nedenle, aerobik kapasitede artış görülmesiyle daha iyi solunum fonksiyonlarına sahip olabilirsiniz. Branş içerisindeki dayanıklılık yetisi artar. Daha yüksek oranda vücut kitle indeksine sahip olmasıyla birlikte sistolik kan basıncı ve kandaki trigliserid seviyesinde artış meydana gelebilir.",
            "AG": "Bu varyanta sahip bireylerde bronşların genişleme potansiyeli ortalama seviyededir. Bu durum, egzersiz sırasında solunum zorluğu yaşama ihtimalini orta seviyede azaltır. Ancak sağlıklı yaşam ve antrenman yöntemleri bu etkiyi kolaylıkla arttırabilir. Vücut yağ oranı yüksek olma eğilimindedir ve vücut kitle indeksi, kan basıncı ve kandaki trigliserid seviyesinde artış meydana gelebilir. Dış etmenlere karşı dayanıklılık oranı yüksek olma eğilimini göstermektedir.",
            "AA": "Bu varyant, bronş genişlemesi ve solunum fonksiyonları üzerinde daha az olumlu etkiye sahip olabilir. Bu durum, spor yaparken solunum zorluğu yaşama riskine karşı koruyucu bir etkiye sahip değildir. Vücut yağ depolama oranı daha düşüktür. Dayanıklılık branşlarında, vücut kitle indeksi düşük olması nedeniyle hareket yeteneği gelişmiştir. Mukavemet oranı daha yüksektir ve kardiyak çıkışı (kalbin bir dakikada pompaladığı kan miktarı) daha düşüktür.",
            "Negatif": "Bu gen bölgesi için herhangi bir sonucunuz yok. Bunun nedeni, belirli popülasyonlarda henüz tanımlanmamış varyantların varlığı olabilir."
        }
    },

    "nos3": {
        "info": "Damardan dokulara oksijen geçişi, dolayısıyla hem enerji metabolizmasına hem de dirence etki eden Nitrik Oksit sinyal molekülünün üretilmesinde etkilidir.",
        "genotype": {
            "TT": "Aerobik kapasite düşüktür ve kan basıncında artışa sebep olur. Bundan dolayı kişiler patlayıcı güce dayalı antrenmanlara daha kolay adapte olabilirler. Özellikle, hız ve sıçramalarda daha iyi performansla ilişkili olduğu görülüyor.",
            "CT": "Düzenli antrenmanlarda orta üstü seviyede dinamik kas yapısına sahip olunabilir. Bu genotipe sahip bireyler kuvvet antrenmanlarına daha iyi yanıt verebilmektedir. Aerobik antrenmanlara adaptasyon ise orta seviyede gözlemlenir, kan basıncında artış bu genotipte de meydana gelmektedir.",
            "CC": "Daha düşük kan basıncına sahip olunup, aerobik kapasitede artış gözlemlenebilir. Uzun süreli dayanıklılık branşlarına adaptasyon daha yüksek orandadır.",
            "Negatif": "Bu gen bölgesi için herhangi bir sonucunuz yok. Bunun nedeni, belirli popülasyonlarda henüz tanımlanmamış varyantların varlığı olabilir."
        }
    },

    "hif1a": {
        "info": "Oksijen yetersiz olduğu ortamlarda kas ve damarlanma yapısının adapte olmasını sağlayan faktördür. ",
        "genotype": {
            "TT": "TT genotipi, HIF1A genindeki iki alelinin de T aleli olduğu durumu ifade eder. Bu genotip, oksijen düzeyindeki değişikliklere yanıt göstermede daha etkilidir. T genotipi olan bireyler, hipoksik koşullara (oksijen eksikliği) daha iyi adapte olabilirler. Bu, yüksek rakımlı bölgelerde yaşayan veya yüksek irtifada spor yapan bireyler için avantajlı olabilir. Aynı zamanda dayanıklılık ve uzun süreli egzersiz performansını arttırır.",
            "CT": "CT genotipi, HIF1A geninde bir alelin T, diğer alelin ise C aleli olduğu durumu ifade eder. Bu genotip, hipoksik koşullara adapte olma yeteneğinde daha ortalama bir yanıtı yansıtmaktadır. Adaptasyon yeteneği, TT genotipine kıyasla daha düşüktür. Ancak yine de total antrenman performansı ve dayanıklılık açısından orta seviyede bir etkiye sahiptir.",
            "CC": "CC genotipi, HIF1A genindeki iki alelinin de C olduğu durumu ifade eder. Bu genotip, hipoksik koşullara adapte olma yeteneğini azaltabilir. C genotipine sahip bireyler, yüksek irtifada branşlarında veya oksijen eksikliği durumlarında (özellikle uzun süreli antrenmanlarda yeterli oksijen katkısının sağlanamadığı koşullarda) adaptasyon zorluğu yaşayabilirler.",
            "Negatif": "Bu gen bölgesi için herhangi bir sonucunuz yok. Bunun nedeni, belirli popülasyonlarda henüz tanımlanmamış varyantların varlığı olabilir."
        }
    },

    "mct1": {
        "info": "Enerji üretimi sırasında oluşturulan ve kaslarda asidik yapısı nedeniyle yorgunluk ve kramplara neden olabilen laktik asidin dokudan kana geçirilmesini sağlayan proteindir. ",
        "genotype": {
            "AA": "AA genotipi, MCT-1 genindeki iki alelinin de A aleli olduğu durumu ifade eder. Bu genotip, taşıyıcı proteinin normal ve optimal seviyedeki işleyişini yansıtır. Enerji metabolizması ve hücre altı materyal trafiği AA genotipinde daha verimli olabilir. Bu durum, spor yaparken enerji üretim ve taşıma süreçlerinin daha etkin işlemesine ve dayanıklılığın artmasına katkıda bulunabilir. Böylelikle, uzun süreli antrenmanlara adaptasyon daha yüksek hale gelir. Kas yorgunlukları ve kramplar daha az sıklıkta görülürken; kas yaralanmalarına eğilim daha yüksektir.",
            "AT": "AT genotipi, MCT-1 geninde bir alelin A, diğer alelin ise T aleli olduğu durumu ifade eder. Bu genotip, taşıyıcı proteinin işlevinde bazı değişikliklere neden olur. Kan dolaşımına laktat aktarımı AT genotipinde ortalamadır. Yüksek yoğunluklu kuvvet antrenmanlarına adaptasyon daha yüksek seviyededir. Kas krampları ve yorgunluklar daha sık görülebilir. Yağsız vücut kütlesine sahip olma eğilimi daha yüksektir.",
            "TT": "TT genotipi, MCT-1 genindeki iki alelinin de T aleli olduğu durumu ifade eder. Bu genotip, taşıyıcı proteinin işlevinin daha az etkili olabileceği bir durumu temsil eder. Dolaşım sistemine laktat transferi TT genotipinde daha düşük olabilir, bu da antrenman sırasındaki yorgunluk hissini arttırabilir. MCT1 TT genotipinin, sprint/güç atletik durumu ile ilişkili olduğu araştırmalar sonucu keşfedilmiştir. Kas yorgunlukları ve kramp görülme sıklığı daha fazladır. Başka bir deyişle, patlayıcı güce dayalı antrenman türüne adapte olma şansı daha yüksektir. Yağsız vücut kütlesine sahip olma eğilimi yüksektir.",
            "Negatif": "Bu gen bölgesi için herhangi bir sonucunuz yok. Bunun nedeni, belirli popülasyonlarda henüz tanımlanmamış varyantların varlığı olabilir."
        }
    },

    "col5a1": {
        "info": "Bağ dokularda, ciltte, kaslara bağlanan dokularda ve kemiklerde sertlik, sıkılık, esneklik ve bütünlük gibi yapıları oluşturan vücutta en çok bulunan kolajen Tip V proteinin üretiminden sorumludur. ",
        "genotype": {
            "CC": "CC genotipi, COL5A1 genindeki iki alelinin de C aleli olduğu durumu ifade eder. CC genotipine sahip bireylerde, bağ ve tendon dokuları daha az esnek olabilir ve yaralanmalara karşı daha kırılgan olabilir. Bu durum, spor yaparken bağ doku ve tendon yaralanmalarının riskinin artabileceği anlamına gelir. Özellikle hızlı ve tekrarlayan hareketlerde yaralanma riski daha yüksek olabilir.",
            "CG": "GC genotipi, COL5A1 geninde bir alelin C, diğer alelin ise G aleli olduğu durumu ifade eder. GC genotipine sahip bireylerde, bağ dokuların dayanıklılığı ve yaralanma riski, CC genotipine göre daha dengeli bir durumda olabilir. Bu bireylerde, spor yaparken bağ ve tendon yaralanmaları riski ortalama düzeyde olabilir.",
            "GG": "GG genotipi, COL5A1 genindeki iki alelinin de G aleli olduğu durumu ifade eder. GG genotipine sahip bireylerde, bağ dokuların esnekliği ve dayanıklılığı daha yüksektir. Bu durum, spor yaparken bağ ve tendon dokularının daha dayanıklı ve esnek olmasına yardımcı olabilir. Özellikle dayanıklılık gerektiren spor branşlarında avantaj sağlayabilir. Şimdiye kadar, COL1A1 geninin GG genotipine sahip olanların tendon veya bağ yaralanmaları yaşama ihtimalinin düşük olduğu ortaya çıkarılmıştır.",
            "Negatif": "Bu gen bölgesi için herhangi bir sonucunuz yok. Bunun nedeni, belirli popülasyonlarda henüz tanımlanmamış varyantların varlığı olabilir."
        }
    },

    "col1a1": {
        "info": " Bağ dokularda, ciltte, kaslara bağlanan dokularda ve kemiklerde sertlik, sıkılık, esneklik ve bütünlük gibi yapıları oluşturan vücutta en çok bulunan kolajen Tip I proteinin üretiminden sorumludur.",
        "genotype": {
            "GG": "GG genotipi, COL1A1 genindeki iki alelinin de G aleli olduğu durumu ifade eder. Bu genotip, tip I kolajen yapısını etkileyebilir ve bağ dokularının dayanıklılığını ve esnekliğini değiştirebilir. Tendinopati, ön çapraz bağ yırtılmaları, omuz çıkığı ve benzeri yumuşak doku yaralanmalarına daha açık olunabilir.",
            "GT": "GT genotipi, COL1A1 geninde bir alelin G, diğer alelin ise T aleli olduğu durumu ifade eder. Bu genotip, tip I kolajen yapısını kısmen etkileyebilir. Yumuşak bağ doku yaralanmalarına karşı kısmen de olsa koruyucu bir allel bulunmaktadır.",
            "TT": "TT genotipi, COL1A1 genindeki iki alelinin de T aleli olduğu durumu ifade eder. TT genotipine sahip bireylerde, kemik ve bağ dokuların esnekliği daha yüksek olabilir. Bu durum, spor yaparken bağ dokuların daha esnek ve hızlı hareketlere daha uygun olabileceği anlamına gelir. Yumuşak doku zedelenmeleri, tendinopati ve ön çapraz bağ yaralanmaları daha az gözlenmektedir.",
            "Negatif": "Bu gen bölgesi için herhangi bir sonucunuz yok. Bunun nedeni, belirli popülasyonlarda henüz tanımlanmamış varyantların varlığı olabilir."
        }
    },

    "il6": {
        "info": "Vücutta yarattığı enflamasyon ile immün sistemini harekete geçiren, zarar gören dokuların hızlıca iyileşmesini sağlayan kimyasalları üretir. ",
        "genotype": {
            "CC": "CC genotipi, IL-6 genindeki iki alelinin de C aleli olduğu durumu ifade eder. CC genotipine sahip bireylerde, inflamasyon tepkileri daha az olabilir. Dayanıklılık antrenmanlarına adapte olma şansı yüksektir. Kas yorgunluğu ve kas zedelenmeleri daha geç iyileşme eğiliminde olabilir. Yağ yakım hızı düşük olabilir.",
            "CG": "CG genotipi, IL-6 geninde bir alelin C, diğer alelin ise G aleli olduğu durumu ifade eder. CG genotipine sahip bireylerde, inflamasyon tepkileri ortalama düzeydedir.",
            "GG": "GG genotipi, IL-6 genindeki iki alelinin de G aleli olduğu durumu ifade eder. GG genotipine sahip bireylerde, inflamasyon tepkileri daha belirgin olabilir. sakatlanmalar daha hızlı iyileşme eğilimindedir. Patlayıcı güce dayalı antrenmanlara daha iyi yanıt verilir. Bu, sprint, halter veya patlayıcı hareketler gibi kısa süreli hız veya güç gerektiren sporlar için faydalıdır.",
            "Negatif": "Bu gen bölgesi için herhangi bir sonucunuz yok. Bunun nedeni, belirli popülasyonlarda henüz tanımlanmamış varyantların varlığı olabilir."
        }
    },

    "cyp1a2": {
        "info": "Karaciğerde bulunan bir enzim olan sitokrom P450 1A2'nin kodladığı bir gendir. Bu gen, kafein, bazı ilaçlar ve diğer maddelerin metabolizmasında önemli bir rol oynar. Kafein gibi bazı maddelerin vücutta nasıl işlendiğini etkileyen bu gen, kafeinin etkilerini bireyler arasında farklılaştırabilir. ",
        "genotype": {
            "AA": "AA genotipi, CYP1A2 genindeki iki alelin de A aleli olduğu durumu ifade eder. Bu genotipe sahip bireylerde, kafein metabolizması daha hızlı olabilir. Kafeine karşı tolerans geliştirme eğilimi yüksektir. Genellikle kafeinin uyarıcı etkilerini daha az hissedebilirler.",
            "AC": "AC genotipi, CYP1A2 geninde bir alelin A, diğer alelin ise C aleli olduğu durumu ifade eder. AC genotipine sahip bireylerde, kafein metabolizması orta seviyede olabilir. Kafeine karşı ortalama bir tolerans geliştirme eğilimi bulunabilir.",
            "CC": "CC genotipi, CYP1A2 genindeki iki alelinin de C aleli olduğu durumu ifade eder. CC genotipine sahip bireylerde, kafein metabolizması daha yavaş olabilir. Bu bireyler genellikle kafeinin uyarıcı etkilerini daha belirgin bir şekilde hissedebilirler ve kafeine karşı daha düşük tolerans geliştirme eğilimi gösterebilirler.",
            "Negatif": "Bu gen bölgesi için herhangi bir sonucunuz yok. Bunun nedeni, belirli popülasyonlarda henüz tanımlanmamış varyantların varlığı olabilir."
        }
    }
}

export default info;