import React from 'react'
import "./LabMenu.css"
import { Menu} from "antd"
import Logo from "../assets/logo/UniqgeneLogo.png"
import { AdminContext } from "../hooks/adminContext";
import { useNavigate,Link } from 'react-router-dom';
import axios from "axios";


function LabMenu() {



  const { admin,setAdmin } = React.useContext(AdminContext)
  const navigate = useNavigate();

  function handleLogout(){
    setAdmin(null);
    
    axios.get("/api2/tokenout").then((res)=> {
      navigate("/");
    })
    
  }



  if (admin) {
    return (
      <div className="menu">
        <Menu mode="horizontal">
        
          <Menu.Item key="1">
            <Link to={"/registerplate"}>PCR-hazırlık</Link>
          </Menu.Item>
          <Menu.Item key="2">
            <Link to={"/registergenotype"}>PCR-plate</Link>
          </Menu.Item>
          <Menu.Item key="3">
            <Link to={"/genotypelist"}>Genotip Listesi</Link>
          </Menu.Item>
          <Menu.Item key="4">
            <button onClick={handleLogout} className='navbarbtn'>Çıkış</button>
          </Menu.Item>
        </Menu>
      </div>
    )
  } else {
    return (
      <div className="menu">


      </div>

    )
  }

}

export default LabMenu