import React from "react";
import LabCalculationTable from "../components/labCalculations/LabCalculationTable";
import "./RegisterPlate.css";
import { Switch, Modal, FloatButton } from 'antd';
import IconButton from "@mui/material/IconButton";
import DeleteIcon from "@mui/icons-material/Delete";
import HelpOutlineRoundedIcon from "@mui/icons-material/HelpOutlineRounded";
import { useState, useEffect, useContext } from "react";
import axios from "axios";
import LabMenu from "../components/LabMenu"
import { AdminContext } from "../hooks/adminContext";
import Popup from "reactjs-popup";

const RegisterPlate = () => {
  const print = () => window.print();
  const { admin } = useContext(AdminContext)
  const cells = [
    "0",
    "1",
    "2",
    "3",
    "4",
    "5",
    "6",
    "7",
    "8",
    "9",
    "10",
    "11",
    "12",
  ];

  const platedesign = {
    A: [],
    B: [],
    C: [],
    D: [],
    E: [],
    F: [],
    G: [],
    H: [],
  };

  const [iplatedesign, setPlatedesign] = useState(platedesign);
  const [plateid, setPlateID] = useState("");
  const [plates, setPlates] = useState([1]);
  const [plateDate, setPlateDate] = useState("Select Date");
  const [selectedPlate, setSelectedPlate] = useState({ date: "Select Date", package: "Select Package" });
  const [swapcode, setSwapcode] = useState("");
  const [gene, setGene] = useState("");
  const [cellNumber, setCellNumber] = useState("");
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [saveName, setSaveName] = useState("Plate seçiniz...");

  const getplate = async () => {
    try {
      await axios
        .post("/api2/getPlate", { phase: "PreLab" })
        .then((response) => {
          if (response.data) {
            // console.log(response.data)
            setPlates(response.data);
          }
        });
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    getplate();
  }, []);

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    return `${date.toLocaleDateString()} ${date.toLocaleTimeString()}`
  }

  function changePlate(event) {
  const [selectedDate, selectedPackage] = event.target.value.split('-');

  setSelectedPlate({ date: selectedDate, package: selectedPackage });

    const element = plates.find(
      (element) =>
        formatDate(element.createdAt) === selectedDate && element.package === selectedPackage
    );
    setPlateDate(formatDate(element.createdAt));
    setPlatedesign(element.platedesign);
    setPlateID(element._id);
    if (element.saveDate) {
      setSaveName("Kitli")
    } else {
      setSaveName("Kitli değil")
    }
  }

  function handleChange(e) {
    e.preventDefault();
    //alert(`The cell ${cellNumber} will be changed!`);
    axios
      .put("/api2/changeCell", {
        id: plateid,
        cell_number: cellNumber.toUpperCase(),
        barcode_number: swapcode.toLowerCase(),
        gene: gene.toLowerCase(),
      })
      .then((response) => {
        console.log(response.data.message)
        if (response.data.message === "Kuyucuk başarıyla değiştirildi!") {
          getplate()
          setPlatedesign(response.data.data);
        }
        alert(response.data.message);//loading button then message
      })
      .catch((error) => {
        console.log(error);
      });
  }

  function handleSwitch(value, cell_number) {
    axios
      .put("/api2/secondSwab", {
        id: plateid,
        cell_number: cell_number,
        second_swab: value,
      })
      .then((response) => {
        //alert(response.data.message);
        //getplate()
        setPlatedesign(response.data.data);
      })
      .catch((error) => {
        console.log(error);
      });
  }

  function handleDelete(cell_number) {
    //alerti silelim mi ????
    alert(`The cell with number ${cell_number} will be deleted.`);
    axios
      .put("/api2/deleteCell", {
        id: plateid,
        cell_number: cell_number,
      })
      .then((response) => {
        //getplate();
        setPlatedesign(response.data.data);
        alert(response.data.message);
      })
      .catch((error) => {
        console.log(error);
      });
  }

  function handleSave(e) {
    e.preventDefault();
    axios
      .post("/api2/savePrePlate", {
        id: plateid,
      })
      .then((response) => {
        getplate()
        setSaveName(response.data.message)
      })
      .catch((error) => {
        console.log(error);
      });
  }

  function handleSubmit(e) {
    e.preventDefault();
    axios
      .post("/api2/submitPrePlate", {
        id: plateid,
      })
      .then((response) => {
        alert(response.data.message);
        setPlates([1]);
        window.location.reload(true);
      })
      .catch((error) => {
        console.log(error);
      });
  }

  if (admin) {
    return (
      <div className="registerplatecontainer">

        <LabMenu />


        <div className="genotypelisthelp">
          <IconButton
            aria-label="help"
            size="small"
            color="error"
            onClick={() => setIsModalVisible(true)}
          >
            <HelpOutlineRoundedIcon />
          </IconButton>
        </div>

        <div className="inputsubcontainer">
          <div className="selectplatecomponent">
            <label htmlFor="plates" className="plateselectiondropdowntitle">
              PCR-hazırlık için oluşturulan uygun tarih ve zamana ait plate'i seçiniz
            </label>
            <select
              name="plates"
              id="plates"
         value={`${selectedPlate.date}-${selectedPlate.package}`}
              onChange={(e) => changePlate(e)}
              className="plateselectiondropdownmenu"
              placeholder="Select Date"
            >
              <option>Plate seç</option>
              {plates.map((e, key) => (
  <option key={key} value={`${formatDate(e.createdAt)}-${e.package}`}>
    {`${formatDate(e.createdAt)}-${e.package === "nutrition" ? "Kafein" : e.package === "sport" ? "Spor" : e.package}`}
  </option>
))}

            </select>
          </div>

          <div className="alterplatecellsubsubcontainer">
            <strong style={{fontSize: "16px", margin: "2px", color: "#124694"}}>Plate Hücrelerine Yerleşim</strong>
            <p style={{fontSize: "12px", margin: "2px", color: "#A84141", fontWeight: "500"}}> Bu işlemi sadece PCR-hazırlık platelerinde yer alan swabkodlar için uygulayabilirsiniz. Plateler arası ya da plate içi değişiklik yapabilirsiniz. Lütfen yerini değiştirmek istediğiniz örneğin bilgilerini giriniz.</p>
            <div className="alterplatecellinput" >
              <label htmlFor="swapcode" className="celltextlabel">Swab Kodu</label>
              <input
                type="text"
                id="swapcode"
                value={swapcode}
                onChange={(e) => setSwapcode(e.target.value)}
                placeholder="Barkod numarasını giriniz..."
                className="cellinputtext"
              />

              <label htmlFor="gene" className="celltextlabel">Gen Bölgesi</label>
              <input
                type="text"
                id="gene"
                name="gene"
                placeholder="Gen bölgesini giriniz..."
                value={gene}
                onChange={(e) => setGene(e.target.value)}
                className="cellinputtext"
              />

              <label htmlFor="cellindex" className="celltextlabel">Yeni hücre</label>
              <input
                type="text"
                id="cellindex"
                name="cellindex"
                placeholder="Hücre indexini giriniz (örn: B3)... "
                value={cellNumber}
                onChange={(e) => setCellNumber(e.target.value)}
                className="cellinputtext"
              />


              <Popup
                trigger={
                  <button className="registerplatebtn">
                    Değiştir
                  </button>
                }
                modal
                closeOnDocumentClick
                contentStyle={{ color: "#515244", width: '400px', height: "200px", backgroundColor: "#ddbbed", fontSize: "24px", textAlign: "center" }}
              >
                {(close) => (<div className="confirmation-popup">
                  <p style={{ fontWeight: "bold" }}>Hücrenin yeri değiştirilecek. Emin misiniz?</p>
                  <button style={{ color: "#423dd9", fontSize: "20px", margin: "20px" }} onClick={(e) => {
                    handleChange(e);
                    close();
                  }} >Evet</button>
                  <button style={{ color: "#ed3b5f", fontSize: "20px", margin: "20px" }} onClick={close}>Hayır</button>
                </div>
                )}
              </Popup>
            </div>
          </div>
        </div>

        <div className="registerplatetablesubcontainer">
          <table className="registerplatetable">
            <thead>
              <tr>
                <th> </th>
                <th>1</th>
                <th>2</th>
                <th>3</th>
                <th>4</th>
                <th>5</th>
                <th>6</th>
                <th>7</th>
                <th>8</th>
                <th>9</th>
                <th>10</th>
                <th>11</th>
                <th>12</th>
              </tr>
            </thead>
            <tbody>
              {Object.keys(iplatedesign).map((name) => {
                let row = iplatedesign[name];
                return (
                  <tr>
                    {cells.map((cell) => {
                      if (cell === "0") {
                        return <td>{name}</td>;
                      } else {
                        let cellno = `${name}${cell}`;

                        let item = row.find(
                          (item) => item.cell_number === cellno
                        );

                        if (item) {
                          return (
                            <td id={item.cell_number}>
                              <tr>{item.swapcode}</tr>
                              <tr>{item.gene}</tr>
                              <tr>
                                <Switch
                                  checkedChildren="2nd"
                                  unCheckedChildren="1st"
                                  checked={item.second_swab}
                                  onChange={(value) =>
                                    handleSwitch(value, item.cell_number)
                                  }
                                />
                                <Popup
                                  trigger={
                                    <IconButton
                                      aria-label="delete"
                                      size="small"
                                      color="error"

                                    >

                                      <DeleteIcon />
                                    </IconButton>
                                  }
                                  modal
                                  closeOnDocumentClick
                                  contentStyle={{ color: "#515244", width: '400px', height: "200px", backgroundColor: "#ddbbed", fontSize: "24px", textAlign: "center" }}
                                >
                                  {(close) => (<div className="confirmation-popup">
                                    <p style={{ fontWeight: "bold" }}>Hücre silinecek. Emin misiniz?</p>
                                    <button style={{ color: "#423dd9", fontSize: "20px", margin: "20px" }} onClick={() => { handleDelete(item.cell_number); close(); }}>Evet</button>
                                    <button style={{ color: "#ed3b5f", fontSize: "20px", margin: "20px" }} onClick={close}>Hayır</button>
                                  </div>
                                  )}
                                </Popup>
                              </tr>
                            </td>
                          );
                        } else {
                          return <td></td>;
                        }
                      }
                    })}
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>

        <div className="buttonsubcontainer">
          <button className="registerplatebutton" onClick={(e) => handleSave(e)}>
            {saveName}
          </button>
          <button className="printplatebutton" onClick={print}>
          Yazdır
          </button>
          <Popup
            trigger={
              <button className="submitplatebutton" >
               PCR-Plate'e Gönder
              </button>
            }
            modal
            closeOnDocumentClick
            contentStyle={{ color: "#515244", width: '400px', height: "200px", backgroundColor: "#ddbbed", fontSize: "24px", textAlign: "center" }}
          >
            {(close) => (
              <div className="confirmation-popup">
                <p>Plate sonuçları PCR-plate'e gönderilecek. Emin misiniz?</p>
                <button style={{ color: "#423dd9", fontSize: "20px", margin: "20px" }} onClick={(e) => { handleSubmit(e); close(); }}>Evet</button>
                <button style={{ color: "#ed3b5f", fontSize: "20px", margin: "20px" }} onClick={close}>Hayır</button>
              </div>
            )}
          </Popup>
        </div>

        <div className="labcalculationtablesubcontainer">
          <LabCalculationTable />
        </div>
        <Modal
          className="helpmodal"
          title="PreLab Kullanımı"
          open={isModalVisible}
          width={800}
          closable={true}
          onCancel={() => setIsModalVisible(false)}
          okButtonProps={{ style: { display: "none" } }}
          cancelButtonProps={{ style: { display: "none" } }}
        >
          <div style={{ textAlign: "left" }}>
            <strong>1)</strong> Plate’in oluşabilmesi için müşteri tarafından gelen girilecek
            örneklerin <strong>“Ready for Isolation”</strong> olarak
            değiştirilmesi gerekmektedir.
            <u>Tekrar girilecek örnekler</u> ise PCR-plate aşamasında genotipleri
            girilip <strong>Gönder</strong> butonuna basıldığında PCR-hazırlık
            aşamasındaki bir plate’e otomatik olarak eklenecektir.
            <br></br>
            <strong>2)</strong> Plate’ler oluşum tarihlerine göre bulunabilir. Oluşum tarihleri
            otomatik olduğu için değiştirilemez. Sol üstte bulunan aşağı doğru
            açılan menüde girilmek istenilen plate seçilir.
            <br></br>
            <strong>3)</strong> Seçilen plate üzerinde <strong>hücre silme</strong>,{" "}
            <strong>hücre değiştirme</strong>{" "}
            <u>(sadece PCR-hazırlık aşamasında olan hücreler için geçerlidir)</u> ve{" "}
            <strong>2. swab bilgisi</strong> değişiklikleri yapılabilir.
            <br></br>
            <strong>**</strong> Silinen hücreler hiç bir şekilde geri alınamaz.
            <br></br>
            <strong>**</strong> Swab seçimi değiştirilmediği sürece 1. swab olarak kaydedilir.
            <br></br>
            <strong>**</strong> PCR-hazırlık aşamasında var olmayan bir barkod numarası (hücre)
            eklenemez.
            <br></br>
            <strong>**</strong> Gen bölgelerinin doğru girişinden laboratuvar ekibi sorumludur.
            <br></br>
            <strong>4)</strong> Hücre değişimi için sağ üstte bulunan <strong>
              Swapkod
            </strong>, <strong>Gen</strong> ve <strong>Hücre</strong> bilgileri
            girilmelidir.
            <br></br>
            <strong>SwabKod: </strong> Eklenmek istenen barkod numarası (başka
            bir PCR-hazırlık plate’inde yer almalıdır)
            <br></br>
            <strong>Gen: </strong> Yukarıda belirtilen barkod numarasına bağlı
            eklenmek istenen gen adı (başka bir PCR-hazırlık plate’inde yer almalıdır)
            <br></br>
            <strong>Hücre: </strong> Bilgilerin eklenmesini istediğiniz hücre (Hücre
            boş olmalıdır, sorun yaşıyor iseniz lütfen Uniqgene ekibine bildiriniz. )
            <br></br>
            <strong>5)</strong> Altta bulunan <strong>Master Mix</strong> tablosu girilen değerlere
            göre otomatik olarak hesaplama yapmaktadır. Tablo saklanmadığı için
            tablo çıktı alınmadan önce doldurulmalıdır sayfa değiştirildiğinde ya
            da yenilendiğinde bu tabloya girilen bilgiler kaybolacaktır.
            <br></br>
            <strong>6)</strong> Laboratuvar esnasında yaşadığınız
            değişiklikleri tekrar plate üzerinde değiştirmeli ve sonrasında göndermelisiniz. Aksi durumda plate hatalı
            olacaktır.
            <br></br>
          </div>
        </Modal>
        <FloatButton.BackTop type="primary"/>
      </div>
    );
  }
};

export default RegisterPlate;
