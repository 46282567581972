const genotypes_dictionary = {
    "actn3": { "V": "XX", "F/V": "RX", "F": "RR", "NEG": "Negatif" },
    "col5a1": { "V": "CC", "F/V": "CG", "F": "GG", "NEG": "Negatif" },
    "col1a1": { "V": "TT", "F/V": "GT", "F": "GG", "NEG": "Negatif" },
    "il6": { "V": "CC", "F/V": "CG", "F": "GG", "NEG": "Negatif" },
    "mct1": { "V": "AA", "F/V": "AT", "F": "TT", "NEG": "Negatif" },
    "adrb2": { "V": "AA", "F/V": "AG", "F": "GG", "NEG": "Negatif" },
    "hif1a": { "V": "CC", "F/V": "CT", "F": "TT", "NEG": "Negatif" },
    "nos3": { "V": "CC", "F/V": "CT", "F": "TT", "NEG": "Negatif" },
    "ppargc1a": { "V": "GG", "F/V": "AG", "F": "AA", "NEG": "Negatif" },
    "ppara": { "V": "GG", "F/V": "CG", "F": "CC", "NEG": "Negatif" },
    "comt": { "V": "AA", "F/V": "AG", "F": "GG", "NEG": "Negatif" },
    "bdnf": { "V": "GG", "F/V": "AG", "F": "AA", "NEG": "Negatif" },
    "cyp1a2": { "F": "AA", "F/V": "AC", "V": "CC", "NEG": "Negatif" },

}

export default genotypes_dictionary;