import { useContext } from "react";
import axios from "axios";
import { AdminContext } from "./adminContext";
import { useNavigate } from "react-router-dom";

export default function useAuth() {
  const { setAdmin } = useContext(AdminContext);
  const navigate = useNavigate();



  const setAdminContext = async() => {
    await axios.get("/api2/token")
    .then((res) => {
      if (res.data.currentuser) {
        setAdmin(res.data.currentuser);
        navigate("/registerplate");
      }
    })
    .catch((err) => {
      console.log(err);
    });
  }


  const loginAdmin = async (data) => {
    const { user, password } = data;
      await axios
        .post("/api2/login", {
          user,
          password,
        })
        .then(async (response) => {
          if (response.data.message === "Login succesful") {
         
            await setAdminContext();

           
          } else {
            alert(response.data.message);
          }
        })
        .catch((err) => {
          alert("Login not successful");
          console.log(err);
        });
  };

  return {
    loginAdmin,
  };
}
