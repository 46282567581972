import {useContext} from "react";
import {AdminContext} from "../hooks/adminContext";
import { useNavigate } from "react-router-dom";

const PrivateRoute  = ({ children}) => {

  const {admin} = useContext(AdminContext);

  const navigate = useNavigate();

    if (admin) {
      console.log("admin",admin);
      return children;
     
} else{
  
  navigate("/");
  
  
}
  

  
    
  };



export default PrivateRoute;