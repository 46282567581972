import React, { useState, useEffect } from "react";
import "./LabCalculationTable.css";
import $ from "jquery";

const LabCalculationTable = () => {
  const [rows, setRows] = useState([
    { id: "actn3", label: "ACTN3", unit: "1x", primerValue: 0.5, masterValue: 10, h2oValue: 7.5 },
    { id: "ppara", label: "PPARa", unit: "1x", primerValue: 0.5, masterValue: 10, h2oValue: 7.5 },
    { id: "ppargc1a", label: "PPARGC1A", unit: "1x", primerValue: 0.5, masterValue: 10, h2oValue: 7.5 },
    { id: "bdnf", label: "BDNF", unit: "1x", primerValue: 0.5, masterValue: 10, h2oValue: 7.5 },
    { id: "col1a1", label: "COL1A1", unit: "1x", primerValue: 0.5, masterValue: 10, h2oValue: 7.5 },
    { id: "il6", label: "IL6", unit: "1x", primerValue: 0.5, masterValue: 10, h2oValue: 7.5 },
    { id: "adrb2", label: "ADRB2", unit: "1x", primerValue: 0.5, masterValue: 10, h2oValue: 7.5 },
    { id: "nos3", label: "NOS3", unit: "1x", primerValue: 0.5, masterValue: 10, h2oValue: 7.5 },
    { id: "hif1a", label: "HIF1A", unit: "1x", primerValue: 0.5, masterValue: 10, h2oValue: 7.5 },
    { id: "col5a1", label: "COL5A1", unit: "1x", primerValue: 0.5, masterValue: 10, h2oValue: 7.5 },
    { id: "mct1", label: "MCT1", unit: "1x", primerValue: 1, masterValue: 10, h2oValue: 7 },
    { id: "comt", label: "COMT", unit: "1x", primerValue: 1, masterValue: 10, h2oValue: 7 },
  ]);

  useEffect(() => {
    rows.forEach(({ id, primerValue, masterValue, h2oValue }) => {
      $(`#val_${id}, #val_${id}_primer, #val_${id}_master, #val_${id}_h2o`).on("input", function () {
        const enteredNumber = Number($(`#val_${id}`).val());
        const totalPrimer = enteredNumber * primerValue;
        const totalMaster = enteredNumber * masterValue;
        const totalH2O = enteredNumber * h2oValue;

        $(`#total_${id}_primer`).val(totalPrimer);
        $(`#total_${id}_master`).val(totalMaster);
        $(`#total_${id}_h2o`).val(totalH2O);
        $(`#total_${id}_dna`).val(2); // Sabit DNA miktarı
      });
    });
  }, [rows]);
  const additionalRow = {
    id: "cyp1a2",
    label: "CYP1A2",
    unit: "1x",
    enzyme: 10,
    forward: 0.5,
    reverse: 0.1,
    primer: 0.2,
    h2o: 4.2,
  };
  useEffect(() => {
 
  
    $(`#val_${additionalRow.id}`).on("input", function () {
      const enteredNumber = Number($(`#val_${additionalRow.id}`).val());
      const enzyme = enteredNumber * additionalRow.enzyme;
      const forward = enteredNumber * additionalRow.forward;
      const reverse = enteredNumber * additionalRow.reverse;
      const primer = enteredNumber * additionalRow.primer;
      const h2o = enteredNumber * additionalRow.h2o;
  
      $(`#total_${additionalRow.id}_enzyme`).val(enzyme);
      $(`#total_${additionalRow.id}_forward`).val(forward);
      $(`#total_${additionalRow.id}_reverse`).val(reverse);
      $(`#total_${additionalRow.id}_primer`).val(primer);
      $(`#total_${additionalRow.id}_h2o`).val(h2o);
      $(`#total_${additionalRow.id}_dna`).val(5); // Sabit DNA miktarı
    });
  }, []);
  
  return (
    <div> 
    <table className="calculationtable1">
      <thead>
        <tr>
          <th>Gen</th>
          <th>Unit</th>
          <th>Sayı girin</th>
          <th colSpan="3">Probe Primer</th>
          <th colSpan="3">Probe Master</th>
          <th colSpan="3"> H2O</th>
          <th>DNA</th>
        </tr>
      </thead>
      <tbody>
        {rows.map(({ id, label, unit }) => (
          <tr key={id}>
            <td>{label}</td>
            <td>{unit}</td>
            <td>
              <input type="number" id={`val_${id}`} className="tableheaderinput" />
            </td>
            {["primer", "master", "h2o"].map((type) => (
              <React.Fragment key={type}>
                <td>{`probe ${type}`}</td>
                <td>
                  <input
                    type="number"
                    value={rows.find((row) => row.id === id)[`${type}Value`]}
                    id={`val_${id}_${type}`}
                    className="tablebodyinput"
                    readOnly
                  />
                  &mu;l
                </td>
                <td>
                  <input
                    type="text"
                    id={`total_${id}_${type}`}
                    className="tablebodyinput"
                    readOnly
                  />
                </td>
              </React.Fragment>
            ))}
            <td>DNA</td>
            <td>
              <input
                type="text"
                value={2} // Sabit DNA miktarı
                id={`total_${id}_dna`}
                className="tablebodyinput"
                readOnly
              />
              &mu;l
            </td>
          </tr>
        ))}
       
        </tbody>
    </table>


        <table className="calculationtable1" style={{marginTop: "30px"}}> 
      <thead>
        <tr>
          <th>Gen</th>
          <th>Unit</th>
          <th>Sayı girin</th>
          <th colSpan="3">Probe Master</th>
          <th colSpan="3">Probe Forward</th>
          <th colSpan="3">Probe Reverse</th>
          <th colSpan="3">Green Dye</th>
          <th colSpan="3"> H2O</th>
          <th colSpan="2">DNA</th>
        </tr>
      </thead>
      <tbody>
        <tr key={additionalRow.id}>
          <td>{additionalRow.label}</td>
          <td>{additionalRow.unit}</td>
          <td>
            <input type="number" id={`val_${additionalRow.id}`} className="tableheaderinput" />
          </td>
          {["enzyme", "forward", "reverse", "primer", "h2o"].map((type) => (
            <React.Fragment key={type}>
              <td>{type === "enzyme" ? "Enzyme" : `probe ${type}`}</td>
              <td>
                <input
                  type="number"
                  value={additionalRow[type]}
                  id={`val_${additionalRow.id}_${type}`}
                  className="tablebodyinput"
                  readOnly={type === "enzyme"}
                />
                {type !== "enzyme" && <>&mu;l</>}
              </td>
              <td>
                <input
                  type="text"
                  id={`total_${additionalRow.id}_${type}`}
                  className="tablebodyinput"
                  readOnly
                />
              </td>
            </React.Fragment>
          ))}
          <td>DNA</td>
          <td>
            <input
              type="text"
              value={5} // Sabit DNA miktarı
              id={`total_${additionalRow.id}_dna`}
              className="tablebodyinput"
              readOnly
            />
            &mu;l
          </td>
        </tr>
      </tbody>
    </table>
    </div>
  );
};

export default LabCalculationTable;
